import React from "react";
import PropTypes from "prop-types";
import Link from "gatsby-link";
import Time from "../components/Time";
import PreviewImg from "../components/PreviewCompatibleImage";

const PostListItem = ({ post }) => {
  return (
    <li className="tile is-ancestor" key={post.id}>
      <div className="tile is-12 is-vertical is-parent">
        <div className="tile">
          <Link to={post.fields.slug}>
            <PreviewImg imageInfo={post.frontmatter} />
            <div className="tile is-12 is-child">
              <header className="title is-size-4">
                <Time
                  pubdate
                  langKey={post.fields.langKey}
                  date={post.frontmatter.date}
                />
                {post.frontmatter.title}
              </header>
              <p className="subtitle">{post.excerpt}</p>
            </div>
          </Link>
        </div>
      </div>
    </li>
  );
};

PostListItem.propTypes = {
  post: PropTypes.shape({
    fields: PropTypes.shape({
      slug: PropTypes.string.isRequired,
      langKey: PropTypes.string.isRequired,
    }),
    frontmatter: PropTypes.shape({
      title: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
      //image: PropTypes.string.isRequired
    }),
    excerpt: PropTypes.string.isRequired,
  }),
};

export default PostListItem;
